@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'ClashDisplay-Extralight';
  src: url('./assets/fonts/clashdisplay/ClashDisplay-Extralight.woff2') format('woff2'),
    url('./assets/fonts/clashdisplay/ClashDisplay-Extralight.woff') format('woff'),
    url('./assets/fonts/clashdisplay/ClashDisplay-Extralight.ttf') format('truetype');
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'ClashDisplay-Light';
  src: url('./assets/fonts/clashdisplay/ClashDisplay-Light.woff2') format('woff2'),
    url('./assets/fonts/clashdisplay/ClashDisplay-Light.woff') format('woff'),
    url('./assets/fonts/clashdisplay/ClashDisplay-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'ClashDisplay-Regular';
  src: url('./assets/fonts/clashdisplay/ClashDisplay-Regular.woff2') format('woff2'),
    url('./assets/fonts/clashdisplay/ClashDisplay-Regular.woff') format('woff'),
    url('./assets/fonts/clashdisplay/ClashDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'ClashDisplay-Medium';
  src: url('./assets/fonts/clashdisplay/ClashDisplay-Medium.woff2') format('woff2'),
    url('./assets/fonts/clashdisplay/ClashDisplay-Medium.woff') format('woff'),
    url('./assets/fonts/clashdisplay/ClashDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'ClashDisplay-Semibold';
  src: url('./assets/fonts/clashdisplay/ClashDisplay-Semibold.woff2') format('woff2'),
    url('./assets/fonts/clashdisplay/ClashDisplay-Semibold.woff') format('woff'),
    url('./assets/fonts/clashdisplay/ClashDisplay-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'ClashDisplay-Bold';
  src: url('./assets/fonts/clashdisplay/ClashDisplay-Bold.woff2') format('woff2'),
    url('./assets/fonts/clashdisplay/ClashDisplay-Bold.woff') format('woff'),
    url('./assets/fonts/clashdisplay/ClashDisplay-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

body, html {
  margin: 0;
  font-family: 'ClashDisplay-Regular', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

.hero-bg {
  background: #000 url('./assets/hero-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -3;
}

.hero-mob-bg {
  background: #000 url('./assets/hero-mob-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -3;
}

.hero-mask {
  background-image: url('./assets/hero-mask.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -2;
}
.hero-mob-mask {
  background-image: url('./assets/hero-mob-mask.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -2;
}

.liner-border{
  background: radial-gradient(circle at 100% 100%, #000000 0, #000000 7px, transparent 7px) 0% 0%/8px 8px no-repeat,
  radial-gradient(circle at 0 100%, #000000 0, #000000 7px, transparent 7px) 100% 0%/8px 8px no-repeat,
  radial-gradient(circle at 100% 0, #000000 0, #000000 7px, transparent 7px) 0% 100%/8px 8px no-repeat,
  radial-gradient(circle at 0 0, #000000 0, #000000 7px, transparent 7px) 100% 100%/8px 8px no-repeat,
  linear-gradient(#000000, #000000) 50% 50%/calc(100% - 2px) calc(100% - 16px) no-repeat,
  linear-gradient(#000000, #000000) 50% 50%/calc(100% - 16px) calc(100% - 2px) no-repeat,
  linear-gradient(310deg, transparent 0%, rgba(255,255,255,0.3) 100%);
}

.form-gradient {
  background: linear-gradient(133deg, rgba(255,255,255,0.10) 0%, rgba(255,255,255,0.20) 100%); 
}

.overview-bg {
  background: #000 url('./assets/overview-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.feature-bg {
  background: #000 url('./assets/feature-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.advisor-bg {
  background: #000 url('./assets/advisor-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.testimonial-bg {
  background: #000 url('./assets/testimonial-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.testimonial-gradient {
  background: linear-gradient(133deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.15) 100%);
  border: 1px solid rgba(255,255,255,0.3);
}

.footer-bg {
  background: #000 url('./assets/footer-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.footer-symbol {
  background: #000 url('./assets/footer-symbol.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.overview-item-1{ background-image: url('./assets/overview-item-1.png'); background-size: contain; background-position: bottom; background-repeat: no-repeat; }
.overview-item-2{ background-image: url('./assets/overview-item-2.png'); background-size: contain; background-position: top; background-repeat: no-repeat; }
.overview-item-1 .overview-item-overlay { background: linear-gradient(180deg, rgba(2,20,29,1) 21%, rgba(9,90,131,0) 100%); }
.overview-item-2 .overview-item-overlay { background: linear-gradient(0deg, rgba(2,20,29,1) 39%, rgba(9,90,131,0) 100%); }


.swiper-button-prev,.swiper-button-next{ display: none; top: unset !important;bottom: -40%;left: 0px;right: 0px;margin:0 auto; border: 1px solid #fff; background-color: #000;width:40px !important;height: 40px !important;border-radius: 50%; }
.swiper-button-prev{ right: 60px !important; }
.swiper-button-next{ left: 60px !important; }
.swiper-button-prev:after,.swiper-button-next:after{ color: #fff;font-size: 22px  !important; }
.swiper-button-prev:hover,.swiper-button-next:hover{ background-color: #fff; }
.swiper-button-prev:hover:after,.swiper-button-next:hover:after{ color: #000; }

.pamm-hero-bg {
  background: #000 url('./assets/pamm-hero.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.date-picker {
  border-color: rgba(255, 255, 255, 0.12); 
  background-color: rgb(34 37 41 / 12%);
}
@media (max-width:768px){
  .mySwiper{ overflow: unset !important; }
  .swiper-button-prev,.swiper-button-next{ display: block; }
  .footer-symbol {
    background-image: url('./assets/footer-mob-symbol.png');
  }
  .pamm-hero-bg {
    background: #3f4147 url('./assets/pamm-mob-hero.png');
    background-position: top top;
    background-size: 100%;
    background-repeat: no-repeat;
  }
}